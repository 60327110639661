<template>
    <router-view/>
</template>

<script>

import { useStore } from 'vuex'
import { computed } from 'vue'

import { WebSocketMessageCode } from './api/wscode'

export default {
  name: 'App',

  setup () {
    const store = useStore()
    const user = computed(() => store.state.auth.user)
    const token = computed(() => store.state.auth.token)
    // const wsAuthState = computed(() => store.state.auth.wsAuthState)

    const messageCount = computed(() => store.state.message.messageCount)
    const updateMessageCount = (count) => store.dispatch('message/updateMessageCount', count)

    const wsAuthOk = () => store.dispatch('auth/wsAuthOk')
    const wsAuthFail = () => store.dispatch('auth/wsAuthFail')
    return {
      user,
      token,
      messageCount,
      updateMessageCount,
      wsAuthOk,
      wsAuthFail
    }
  },

  created () {
    this.$socket.client.connect()
    console.log('Connecting...')
  },

  beforeUnmounted () {
    console.log('Disconnecting')
    this.$socket.client.disconnect()
  },

  sockets: {
    connect () {
      console.log('Connected.')
      this.sendWsAuth()
    },

    disconnect () {
      console.log('Disconnected.')
      this.wsAuthFail()
    },

    message (data) {
      try {
        const rep = JSON.parse(data)
        // console.log(rep)
        if (rep.code === WebSocketMessageCode.Authorization) {
          if (rep.auth) {
            console.log('WS Authorization successful')
            this.wsAuthOk()
          } else {
            console.log('WS Authorization failed')
            this.wsAuthFail()
          }
        } else if (rep.code === WebSocketMessageCode.Bargain) {
          if (rep.ret) {
            this.$toast.success('Your bargain request has been accepted')
            this.updateMessageCount(this.messageCount + 1)
          } else {
            this.$toast.info('Your bargain request has been rejected')
          }
        } else {
          console.log('Unrecognized data')
        }
      } catch (e) {
        console.log(e)
      }
    },

    customEvent () {
    }
  },

  methods: {
    sendWsAuth () {
      if (this.user.id && this.$socket.connected) {
        console.log('Sending auth information...')
        this.$socket.client.emit('auth', this.user.id, this.token)
      } else {
        console.log('Retry sending auth information in 5 seconds...')
        setTimeout(() => {
          this.sendWsAuth()
        }, 5000)
      }
    }
  }
}
</script>

<style>

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  min-width: 320px;
  /*max-width: 576px;*/
  margin: 0 auto;

  background: #f1f1f1;
  /*text-align: center;*/
  /*color: #2c3e50;*/
}

</style>
