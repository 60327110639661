// loading.js
let htmlElement

const htmlString = `
  <div style="
    background: transparent;
    z-index: 10000;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    position: absolute;
    ">
    <div style="background: black; padding: 1rem 4rem; opacity: 0.6; color: white; border-radius: 10px; display: flex; justify-content: center; align-items: center">
      <div id="$$$toast$$$"></div>
    </div>
  </div>
`

export default function showToast (content = 'Loading...', timeout = 1500) {
  if (!htmlElement) {
    const _doc = new DOMParser().parseFromString(htmlString, 'text/html')
    htmlElement = _doc.body.firstChild
    document.body.appendChild(htmlElement)
    _doc.close()
  }
  document.getElementById('$$$toast$$$').innerText = content
  htmlElement.style.display = 'flex'

  setTimeout(() => {
    if (htmlElement) {
      htmlElement.style.display = 'none'
    }
  }, timeout)
}
