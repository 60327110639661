import { MESSAGE_COUNT } from '../types'

const state = {
  messageCount: localStorage.getItem(MESSAGE_COUNT) || 0
}

const mutations = {
  SET_MESSAGE_COUNT: (state, count) => {
    state.messageCount = count
  }
}

const actions = {
  updateMessageCount ({ commit }, count) {
    localStorage.setItem(MESSAGE_COUNT, count)
    commit('SET_MESSAGE_COUNT', count)
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}
