export const ACCESS_TOKEN = 'Access-Token'
export const LOGIN_STATE = 'Logged-In'
export const WS_STATE = 'WS'
export const USER_INFO = 'User-Info'
export const USER_ID = 'UID'
export const CART_ITEMS = 'Cart-Items'
export const PROVIDER_ITEMS = 'Provider-Items'
export const PICKUP_ITEMS = 'Pickups'
export const SELF_PICKUP = 'Self-Pickup'
export const SELECTED_COUNTRY = 'Selected-Country'
export const USER_ADDRESS = 'User-Address'
export const SELECTED_ADDRESS = 'Selected-Address-Id'
export const SELECTED_PROVIDER = 'Selected-Provider-Id'
export const ORDER_INFO = 'Order-Info'
export const ORDER_FILTER_STATUS = 'Order-Filter'
export const REER_INFO = 'Reer-Info'
export const MESSAGE_COUNT = 'Message'
export const CART_COUNT = 'Cart'
