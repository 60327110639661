import {
  PROVIDER_ITEMS, PICKUP_ITEMS, SELF_PICKUP,
  SELECTED_COUNTRY, USER_ADDRESS, SELECTED_ADDRESS,
  SELECTED_PROVIDER, ORDER_INFO, ORDER_FILTER_STATUS
} from '@/store/types'

const state = {
  providers: localStorage.getItem(PROVIDER_ITEMS) ? JSON.parse(localStorage.getItem(PROVIDER_ITEMS)) : [],
  pickups: localStorage.getItem(PICKUP_ITEMS) ? JSON.parse(localStorage.getItem(PICKUP_ITEMS)) : [],
  selfPickup: JSON.parse(localStorage.getItem(SELF_PICKUP)) || true,
  selectedCountry: localStorage.getItem(SELECTED_COUNTRY) || '',
  addresses: localStorage.getItem(USER_ADDRESS) ? JSON.parse(localStorage.getItem(USER_ADDRESS)) : [],
  selectedAddressId: localStorage.getItem(SELECTED_ADDRESS) ? parseInt(localStorage.getItem(SELECTED_ADDRESS)) : undefined,
  selectedProviderId: localStorage.getItem(SELECTED_PROVIDER) ? parseInt(localStorage.getItem(SELECTED_PROVIDER)) : undefined,
  order: JSON.parse(localStorage.getItem(ORDER_INFO)) || [],
  status: localStorage.getItem(ORDER_FILTER_STATUS) || ''
}

const mutations = {

  SET_ORDER_STATUS: (state, status) => {
    state.status = status
  },

  SET_ORDER_INFO: (state, order) => {
    state.order = order
  },

  SET_PICKUPS: (state, pickups) => {
    state.pickups = pickups
  },

  SET_PROVIDERS: (state, providers) => {
    state.providers = providers
  },

  SET_SELF_PICKUP: (state, selfPickup) => {
    state.selfPickup = selfPickup
  },

  SET_SELECTED_COUNTRY: (state, selectedCountry) => {
    state.selectedCountry = selectedCountry
  },

  SET_USER_ADDRESSES: (state, addresses) => {
    state.addresses = addresses
  },

  SET_SELECTED_ADDRESS_ID: (state, addressId) => {
    state.selectedAddressId = addressId
  },

  SET_DEFAULT_ADDRESS: (state, addressId) => {
    state.addresses.forEach((item, index) => {
      if (item.address.default) {
        state.addresses[index].address.default = false
      }
      if (item.address.id === addressId) {
        state.addresses[index].address.default = true
      }
    })

    localStorage.setItem(USER_ADDRESS, JSON.stringify(state.addresses))
  },

  SET_SELECTED_PROVIDER_ID: (state, providerId) => {
    state.selectedProviderId = providerId
  }
}

const actions = {
  setOrderStatus ({ commit }, status) {
    localStorage.setItem(ORDER_FILTER_STATUS, status)
    commit('SET_ORDER_STATUS', status)
  },

  setOrderInfo ({ commit }, order) {
    localStorage.setItem(ORDER_INFO, JSON.stringify(order))
    commit('SET_ORDER_INFO', order)
  },

  // eslint-disable-next-line no-unused-vars
  updateOrderInfo ({ commit }, order) {
    const stocks = state.order.stocks
    state.order = { stocks, ...order }
    localStorage.setItem(ORDER_INFO, JSON.stringify(state.order))
  },

  setSelectedProviderId ({ commit }, providerId) {
    localStorage.setItem(SELECTED_ADDRESS, providerId)
    commit('SET_SELECTED_PROVIDER_ID', providerId)
  },

  setDefaultAddress ({ commit }, addressId) {
    if (!addressId && state.addresses.length > 1) {
      commit('SET_DEFAULT_ADDRESS', state.addresses[0].address.id)
    } else {
      commit('SET_DEFAULT_ADDRESS', addressId)
    }
  },

  clearUserAddress ({ commit }) {
    localStorage.removeItem(USER_ADDRESS)
    commit('SET_USER_ADDRESSES', [])
  },

  setSelectedAddressId ({ commit }, addressId) {
    localStorage.setItem(SELECTED_ADDRESS, addressId)
    commit('SET_SELECTED_ADDRESS_ID', addressId)
  },

  saveUserAddresses ({ commit }, addresses) {
    localStorage.setItem(USER_ADDRESS, JSON.stringify(addresses))
    commit('SET_USER_ADDRESSES', addresses)
  },

  savePickups ({ commit }, pickups) {
    localStorage.setItem(PICKUP_ITEMS, JSON.stringify(pickups))
    commit('SET_PICKUPS', pickups)
  },

  saveProviders ({ commit }, providers) {
    localStorage.setItem(PROVIDER_ITEMS, JSON.stringify(providers))
    commit('SET_PROVIDERS', providers)
  },

  clearPickups ({ commit }) {
    localStorage.removeItem(PICKUP_ITEMS)
    commit('SET_PICKUPS', [])
  },

  clearProviders ({ commit }) {
    localStorage.removeItem(PROVIDER_ITEMS)
    commit('SET_PROVIDERS', [])
  },

  setSelfPickup ({ commit }, selfPickup) {
    localStorage.setItem(SELF_PICKUP, selfPickup)
    commit('SET_SELF_PICKUP', selfPickup)
  },

  setSelectedCountry ({ commit }, selectedCountry) {
    localStorage.setItem(SELECTED_COUNTRY, selectedCountry)
    commit('SET_SELECTED_COUNTRY', selectedCountry)
  },

  clearAll () {
    localStorage.removeItem(USER_ADDRESS)
    localStorage.removeItem(PICKUP_ITEMS)
    localStorage.removeItem(PROVIDER_ITEMS)
    localStorage.removeItem(SELF_PICKUP)
    localStorage.removeItem(SELECTED_COUNTRY)
  }
}

const getters = {
  pickups: state => {
    return state.pickups
  },
  selectedCountry: state => {
    return state.selectedCountry
  },
  addresses: state => {
    return state.addresses
  },
  selectedAddressId: state => {
    return state.selectedAddressId
  },
  selectedProviderId: state => {
    return state.selectedProviderId
  },
  providers: state => {
    return state.providers
  },
  orderInfo: state => {
    return state.orderInfo
  }
}

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations
}
