import { library } from '@fortawesome/fontawesome-svg-core'

import FontAwesomeIcon from '@/libs/FontAwesomeIcon.vue'
// import { faInstagram, faTwitter } from "@fortawesome/free-brands-svg-icons";

import {
  faCreditCard,
  faCommentDots,
  faBell,
  faHeart as faHeartCircle
} from '@fortawesome/free-regular-svg-icons'

import {
  faFacebook,
  faTwitter,
  faInstagram,
  faGooglePlus,
  faWhatsapp,
  faLinkedin, faLinkedinIn
} from '@fortawesome/free-brands-svg-icons'

import {
  faBars,
  faSignInAlt,
  faSignOutAlt,
  faUser,
  faCartPlus,
  faWindowClose,
  faWarehouse,
  faListAlt,
  // faCreditCard,
  faPercent,
  faPlane,
  faAngleDoubleRight, faAngleDown, faAngleUp, faAngleRight,
  faMapMarkerAlt, faTrashAlt,
  faAngleLeft, faSortNumericUp, faSortNumericDown, faArrowsAltV,
  faPlus, faMinus,
  faTrash, faCopy, faEdit,
  faLongArrowAltRight, faSearch, faTimesCircle, faAngleDoubleDown, faSyncAlt, faHeart
} from '@fortawesome/free-solid-svg-icons'

library.add(
  faBars,
  faUser,
  faWindowClose,
  faCartPlus,
  faSignInAlt,
  faSignOutAlt,
  faWarehouse,
  faListAlt,
  faCreditCard,
  faPercent,
  faPlane,
  faAngleDoubleRight, faAngleDown, faAngleUp, faAngleRight,
  faFacebook, faGooglePlus, faTwitter, faInstagram, faWhatsapp, faLinkedin, faLinkedinIn,
  faMapMarkerAlt, faTrashAlt,
  faAngleLeft,
  faSortNumericUp, faSortNumericDown, faArrowsAltV,
  faPlus, faMinus,
  faTrash, faCopy, faEdit,
  faLongArrowAltRight, faSearch, faTimesCircle, faAngleDoubleDown, faSyncAlt, faHeart, faHeartCircle, faCommentDots,
  faBell
)

export { FontAwesomeIcon }
