import axios from 'axios'
import store from '@/store'
import showToast from '../plugins/loading/globalToast'
import { ACCESS_TOKEN } from '@/store/types'

// 创建 axios 实例
const request = axios.create({
  // API 请求的默认前缀
  baseURL: process.env.VUE_APP_BASE_API_URL,
  timeout: process.env.NODE_ENV === 'development' ? 600000 : 60000 // 请求超时时间
})

// 异常拦截处理器
const errorHandler = (error) => {
  if (error.response) {
    const data = error.response.data
    // 从 localstorage 获取 token
    // const token = localStorage.getItem(ACCESS_TOKEN)
    if (error.response.status === 403) {
      showToast('403 Forbidden! ' + data.message)
    } else if (error.response.status === 401) {
      // 当检测到401错误时，直接调用logout
      store.dispatch('auth/logout').then(() => {
        showToast('No authorization, force to logout')
      })
      setTimeout(() => {
        window.location.reload()
      }, 1500)
    } else if (error.response.status === 422) {
      // 当检测到422错误时，直接调用logout。
      // 一般是因为用户在其他地方登录了
      store.dispatch('auth/logout').then(() => {
        showToast('You have logged in another place, please re-login')
      })
      setTimeout(() => {
        window.location.reload()
      }, 1500)
    }
  } else {
    if (error.message === 'Network Error') {
      showToast('Oops... your network appears shutdown, please retry later.')
    }
  }
  return Promise.reject(error)
}

// request interceptor
request.interceptors.request.use(config => {
  const token = localStorage.getItem(ACCESS_TOKEN)
  // 如果 token 存在
  // 让每个请求携带自定义 token 请根据实际情况自行修改
  if (token) {
    config.headers.Authorization = 'JWT ' + token
  }

  return config
}, errorHandler)

// response interceptor
request.interceptors.response.use((response) => {
  return response.data
}, errorHandler)

export default request

export {
  request as axios
}
