import {
  REER_INFO
} from '@/store/types'

const state = {
  countries: JSON.parse(localStorage.getItem(REER_INFO))?.countries || [],
  categories: JSON.parse(localStorage.getItem(REER_INFO))?.categories || [],
  grades: JSON.parse(localStorage.getItem(REER_INFO))?.grades || []
}

const mutations = {
  SET_COUNTRIES: (state, countries) => {
    state.countries = countries
  },
  SET_CATEGORIES: (state, brands) => {
    state.countries = brands
  },
  SET_GRADES: (state, grades) => {
    state.countries = grades
  }
}

function loadReerInfo () {
  return JSON.parse(localStorage.getItem(REER_INFO)) || undefined
}

const actions = {
  cacheCountries ({ commit }, { countries }) {
    let reerInfo = loadReerInfo()
    if (reerInfo) {
      reerInfo.countries = countries
    } else {
      reerInfo = { countries: countries }
    }
    localStorage.setItem(REER_INFO, JSON.stringify(reerInfo))

    commit('SET_COUNTRIES', countries)
  },

  cacheCategories ({ commit }, { categories }) {
    let reerInfo = loadReerInfo()
    if (reerInfo) {
      reerInfo.categories = categories
    } else {
      reerInfo = { categories: categories }
    }
    localStorage.setItem(REER_INFO, JSON.stringify(reerInfo))

    commit('SET_CATEGORIES', categories)
  },

  cacheGrades ({ commit }, { grades }) {
    let reerInfo = loadReerInfo()
    if (reerInfo) {
      reerInfo.grades = grades
    } else {
      reerInfo = { grades: grades }
    }
    localStorage.setItem(REER_INFO, JSON.stringify(reerInfo))

    commit('SET_GRADES', grades)
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}
