import {
  ACCESS_TOKEN, LOGIN_STATE, USER_INFO,
  CART_ITEMS, SELECTED_COUNTRY,
  USER_ADDRESS, SELECTED_ADDRESS,
  SELECTED_PROVIDER, SELF_PICKUP, PICKUP_ITEMS, PROVIDER_ITEMS, ORDER_INFO, USER_ID
} from '@/store/types'
import { refreshUser } from '@/api/user'

const state = {
  loggedIn: localStorage.getItem(LOGIN_STATE) || false,
  wsAuthState: false,
  user: JSON.parse(localStorage.getItem(USER_INFO)) || {},
  userId: localStorage.getItem(USER_ID) || '',
  token: localStorage.getItem(ACCESS_TOKEN) || undefined
}

const mutations = {
  SET_STATUS: (state, logged) => {
    state.loggedIn = logged
  },
  SET_USER: (state, user) => {
    state.user = user
  },
  SET_WS_AUTH_STATE: (state, wsAuthStatus) => {
    state.wsAuthState = wsAuthStatus
  },
  SET_USERID: (state, userId) => {
    state.userId = userId
  },
  SET_BALANCE: (state, balance) => {
    if (state.user) {
      state.user.account_balance = balance
      localStorage.setItem(USER_INFO, JSON.stringify(state.user))
    }
  },
  SET_TOKEN: (state, token) => {
    state.token = token
  }
}

const actions = {
  loginSucceed ({ commit }, {
    token,
    logged,
    user
  }) {
    localStorage.setItem(ACCESS_TOKEN, token)
    localStorage.setItem(LOGIN_STATE, logged)
    localStorage.setItem(USER_INFO, JSON.stringify(user))
    localStorage.setItem(USER_ID, user.phone || '')
    commit('SET_TOKEN', token)
    commit('SET_STATUS', logged)
    commit('SET_USER', user)
  },

  wsAuthOk ({ commit }) {
    commit('SET_WS_AUTH_STATE', true)
  },

  wsAuthFail ({ commit }) {
    commit('SET_WS_AUTH_STATE', false)
  },

  logout ({ commit }) {
    localStorage.removeItem(ACCESS_TOKEN)
    localStorage.removeItem(LOGIN_STATE)
    localStorage.removeItem(CART_ITEMS)
    localStorage.removeItem(SELECTED_COUNTRY)
    localStorage.removeItem(USER_ADDRESS)
    localStorage.removeItem(SELECTED_ADDRESS)
    localStorage.removeItem(SELECTED_PROVIDER)
    localStorage.removeItem(SELF_PICKUP)
    localStorage.removeItem(PICKUP_ITEMS)
    localStorage.removeItem(USER_INFO)
    localStorage.removeItem(PROVIDER_ITEMS)
    localStorage.removeItem(ORDER_INFO)
    commit('SET_STATUS', false)
    commit('SET_USER', {})
    commit('SET_WS_AUTH_STATE', false)
    commit('SET_TOKEN', undefined)
    window.location.reload()
  },

  registerSucceed ({ commit }, userId) {
    localStorage.setItem(USER_ID, userId || '')
    commit('SET_USERID', userId)
  },

  refreshUserInfo ({ commit }) {
    refreshUser().then(res => {
      if (res.code === 0) {
        localStorage.setItem(USER_INFO, JSON.stringify(res.data.user))
        localStorage.setItem(USER_ID, res.data.user.phone || '')
        commit('SET_USER', res.data.user)
      }
    })
  },

  updateBalance ({ commit }, balance) {
    commit('SET_BALANCE', balance)
  }
}

const getters = {
  isLoggedIn: (state, getters, rootState) => {
    return state.loggedIn
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
