import { createRouter, createWebHistory } from 'vue-router'

import store from '@/store'

const routes = [
  // {
  //   path: '/daily',
  //   name: '/Daily',
  //   component: () => import(/* webpackChunkName: "daily" */ '@/views/Daily')
  // },
  {
    path: '/',
    name: 'home',
    component: () => import(/* webpackChunkName: "home" */ '@/views/Daily'),
    meta: { requiresAuth: false }
    // redirect: '/home/dashboard',
    // children: [
    //     {
    //         path: '/home/dashboard',
    //         name: 'dashboard',
    //         component: () => import (/* webpackChunkName: "home" */ '@/views/home/Dashboard'),
    //         meta: { requiresAuth: true }
    //     },
    //     {
    //         path: '/home/inventory',
    //         name: 'products',
    //         component: () => import (/* webpackChunkName: "home" */ '@/views/product/ProductList'),
    //         meta: { requiresAuth: true }
    //     },
    //     {
    //         path: '/home/inventory/new',
    //         name: 'newProduct',
    //         component: () => import (/* webpackChunkName: "home" */ '@/views/product/ProductEdit'),
    //         meta: { requiresAuth: true }
    //     },
    //     {
    //         path: '/home/settings',
    //         name: 'settings',
    //         component: () => import (/* webpackChunkName: "user" */ '@/views/user/Settings'),
    //         meta: { requiresAuth: true }
    //     },
    //     {
    //         path: '/home/orders',
    //         name: 'orders',
    //         component: () => import (/* webpackChunkName: "user" */ '@/views/order/OrderList'),
    //         meta: { requiresAuth: true }
    //     },
    //     {
    //         path: '/home/stock',
    //         name: 'stock',
    //         component: () => import (/* webpackChunkName: "user" */ '@/views/product/Stock'),
    //         meta: { requiresAuth: true }
    //     }
    // ]
  },
  {
    path: '/message',
    name: 'message',
    component: () => import(/* webpackChunkName: "message" */ '@/components/layout/InfoLayout'),
    redirect: '/message/list',
    children: [
      {
        path: '/message/list',
        name: 'messageList',
        component: () => import(/* webpackChunkName: "message" */ '@/views/message/MessageList'),
        meta: { requiresAuth: true }
      }
    ]
  },
  {
    path: '/wishlist',
    name: 'wishlist',
    component: () => import(/* webpackChunkName: "wishlist" */ '@/components/layout/InfoLayout'),
    redirect: '/wishlist/list',
    children: [
      {
        path: '/wishlist/list',
        name: 'wishList',
        component: () => import(/* webpackChunkName: "wishlist" */ '@/views/wishlist/Wishlist'),
        meta: { requiresAuth: true }
      }
    ]
  },
  {
    path: '/products',
    name: 'products',
    component: () => import(/* webpackChunkName: "product" */ '@/components/layout/ProductLayout'),
    redirect: '/product/list',
    children: [
      {
        path: '/product/list',
        name: 'productList',
        component: () => import(/* webpackChunkName: "product" */ '@/views/product/Product'),
        meta: { requiresAuth: true }
      }
    ]
  },
  {
    path: '/info',
    name: 'info',
    component: () => import(/* webpackChunkName: "home" */ '@/components/layout/InfoLayout'),
    children: [
      {
        path: '/info/about',
        name: 'about',
        component: () => import(/* webpackChunkName: "home" */ '@/views/About')
      },
      {
        path: '/info/faq',
        name: 'faq',
        component: () => import(/* webpackChunkName: "home" */ '@/views/FAQ')
      }
    ]
  },
  {
    path: '/cart',
    name: 'cart',
    component: () => import(/* webpackChunkName: "user" */ '@/components/layout/UserLayout'),
    redirect: '/cart/list',
    children: [
      {
        path: '/cart/list',
        name: 'cartList',
        component: () => import(/* webpackChunkName: "cart" */ '@/views/cart/Cart'),
        meta: { requiresAuth: true }
      }
    ]
  },
  {
    path: '/order',
    name: 'order',
    component: () => import(/* webpackChunkName: "order" */ '@/components/layout/UserLayout'),
    redirect: '/order/list',
    children: [
      {
        path: '/order/list',
        name: 'orderList',
        component: () => import(/* webpackChunkName: "order" */ '@/views/order/SimpleOrderList'),
        meta: { requiresAuth: true }
      }
    ]
  },
  {
    path: '/user',
    name: 'user',
    component: () => import(/* webpackChunkName: "user" */ '@/components/layout/UserLayout'),
    children: [
      {
        path: '/user/login',
        name: 'login',
        component: () => import(/* webpackChunkName: "user" */ '@/views/user/LoginV2')
      },
      {
        path: '/user/register',
        name: 'register',
        component: () => import(/* webpackChunkName: "user" */ '@/views/user/Register')
      },
      {
        path: '/user/verify',
        name: 'verifyUser',
        component: () => import(/* webpackChunkName: "user" */ '@/views/user/VerifyRegister')
      },
      {
        path: '/user/payment',
        name: 'payment',
        component: () => import(/* webpackChunkName: "user" */ '@/views/order/Payment'),
        meta: { requiresAuth: true }
      },
      {
        path: '/user/final_payment',
        name: 'finalPayment',
        component: () => import(/* webpackChunkName: "user" */ '@/views/order/FinalPayment'),
        meta: { requiresAuth: true }
      },
      {
        path: '/user/pay_result',
        name: 'payResult',
        component: () => import(/* webpackChunkName: "user" */ '@/views/order/PayResult'),
        meta: { requiresAuth: true }
      },
      {
        path: '/user/charge',
        name: 'charge',
        component: () => import(/* webpackChunkName: "user" */ '@/views/user/Charge'),
        meta: { requiresAuth: true }
      },
      {
        path: '/user/add_address',
        name: 'addAddress',
        component: () => import(/* webpackChunkName: "user" */ '@/views/address/AddAddress'),
        meta: { requiresAuth: true }
      },
      {
        path: '/user/address_list',
        name: 'addressList',
        component: () => import(/* webpackChunkName: "user" */ '@/views/address/AddressList'),
        meta: { requiresAuth: true }
      },
      {
        path: '/user/balance_sheet',
        name: 'balanceSheet',
        component: () => import(/* webpackChunkName: "user" */ '@/views/user/BalanceSheet'),
        meta: { requiresAuth: true }
      },
      {
        path: '/user/feedback',
        name: 'feedback',
        component: () => import(/* webpackChunkName: "user" */ '@/views/user/Feedback'),
        meta: { requiresAuth: true }
      },
      {
        path: '/user/shipping_provider',
        name: 'shippingProvider',
        component: () => import(/* webpackChunkName: "user" */ '@/views/shipping/ShippingProvider'),
        meta: { requiresAuth: true }
      },
      {
        path: '/user/shipping_info',
        name: 'shippingInfo',
        component: () => import(/* webpackChunkName: "user" */ '@/views/shipping/ShippingInfo'),
        meta: { requiresAuth: true }
      },
      {
        path: '/user/order_result',
        name: 'orderResult',
        component: () => import(/* webpackChunkName: "user" */ '@/views/order/SubmitOrderResult'),
        meta: { requiresAuth: true }
      },
      {
        path: '/user/center',
        name: 'userCenter',
        component: () => import(/* webpackChunkName: "user" */ '@/views/user/UserCenter'),
        meta: { requiresAuth: true }
      }
    ]
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    component: () => import(/* webpackChunkName: "fail" */ '@/views/exception/404')
  }
]

export const router = createRouter({
  history: createWebHistory(),
  routes: routes
})

// 路由控制，return true放行，return false禁止
// eslint-disable-next-line no-unused-vars
router.beforeEach((to, from) => { // disable-no-unused-vars
  if (to.meta.requiresAuth && !store.state.auth.loggedIn) {
    return {
      path: '/user/login',
      query: { redirect: to.fullPath }
    }
  }
  return true
})

// export default router
