import request from '@/utils/request'

const userApi = {
  Login: '/b/user/login',
  Register: '/b/user/register',
  Verify: '/b/user/verify',
  SendCode: '/b/user/send_code',

  Refresh: '/b/user/refresh',

  ListCart: '/b/cart/list',
  AddToCart: '/b/cart/add',
  DelFromCart: '/b/cart/del',
  UpdateCartQty: '/b/cart/update',
  UpdateBargainPrice: '/b/cart/bargain',

  ListAddress: '/b/user/addresses',
  AddressAndPickup: '/b/user/addresses_pickups',
  ListShipProvider: '/b/user/ship_providers',

  ChangeDefaultAddress: '/b/user/default_address',
  DeleteAddress: '/b/user/del_address',
  AddAddress: '/b/user/add_address',
  ListBook: '/b/user/books',
  GetUserCartAndMessageCount: '/b/user/cart_message',
  GetUserUnreadMessageCount: '/b/user/unread_message',
  GetUserMessages: '/b/user/messages',
  UpdateUserMessage: '/b/user/update_message'
}

export function getUserCartAndMessageCount (parameter) {
  return request({
    url: userApi.GetUserCartAndMessageCount,
    method: 'post',
    data: parameter
  })
}

export function getUserUnreadMessageCount (parameter) {
  return request({
    url: userApi.GetUserUnreadMessageCount,
    method: 'post',
    data: parameter
  })
}

export function getUserMessages (parameter) {
  return request({
    url: userApi.GetUserMessages,
    method: 'post',
    data: parameter
  })
}

export function updateUserMessage (parameter) {
  return request({
    url: userApi.UpdateUserMessage,
    method: 'post',
    data: parameter
  })
}

export function getUserBooks (parameter) {
  return request({
    url: userApi.ListBook,
    method: 'post',
    data: parameter
  })
}

export function refreshUser (parameter) {
  return request({
    url: userApi.Refresh,
    method: 'post',
    data: parameter
  })
}

export function addAddress (parameter) {
  return request({
    url: userApi.AddAddress,
    method: 'post',
    data: parameter
  })
}

export function deleteAddress (parameter) {
  return request({
    url: userApi.DeleteAddress,
    method: 'post',
    data: parameter
  })
}

export function changeDefaultAddress (parameter) {
  return request({
    url: userApi.ChangeDefaultAddress,
    method: 'post',
    data: parameter
  })
}

export function login (parameter) {
  return request({
    url: userApi.Login,
    method: 'post',
    data: parameter
  })
}

export function registerUser (parameter) {
  return request({
    url: userApi.Register,
    method: 'post',
    data: parameter
  })
}

export function verifyUser (parameter) {
  return request({
    url: userApi.Verify,
    method: 'post',
    data: parameter
  })
}

export function sendCode (parameter) {
  return request({
    url: userApi.SendCode,
    method: 'post',
    data: parameter
  })
}

export function listCart (parameter) {
  return request({
    url: userApi.ListCart,
    method: 'post',
    data: parameter
  })
}

export function addToCart (parameter) {
  return request({
    url: userApi.AddToCart,
    method: 'post',
    data: parameter
  })
}

export function delFromCart (parameter) {
  return request({
    url: userApi.DelFromCart,
    method: 'post',
    data: parameter
  })
}

export function updateCartQty (parameter) {
  return request({
    url: userApi.UpdateCartQty,
    method: 'post',
    data: parameter
  })
}

export function updateBargainPrice (params) {
  return request({
    url: userApi.UpdateBargainPrice,
    method: 'post',
    data: params
  })
}

export function getUserAddresses (parameter) {
  return request({
    url: userApi.ListAddress,
    method: 'post',
    data: parameter
  })
}

export function getUserAddressesAndPickups (parameter) {
  return request({
    url: userApi.AddressAndPickup,
    method: 'post',
    data: parameter
  })
}

export function getShipProviders (parameter) {
  return request({
    url: userApi.ListShipProvider,
    method: 'post',
    data: parameter
  })
}
