import { createApp } from 'vue'
import { router } from '@/router'
import store from '@/store'
import App from './App.vue'

import '../scss/global.scss'

import { FontAwesomeIcon } from '@/plugins/font-awesome'

import { Toaster } from '@/plugins/toaster'
import Loading from '@/plugins/loading'

import { secondsRemain, toThousands } from '@/utils/util'

import 'bootstrap'

import VueSocketIOExt from './plugins/ws'
import { io } from 'socket.io-client'
const socket = io(process.env.VUE_APP_WS_URL, {
  autoConnect: false,
  cors: {
    origin: process.env.VUE_APP_ORIGIN_URL,
    methods: ['GET', 'POST']
  }
})

createApp(App)
  .use(router)
  .use(store)
  .component('fa', FontAwesomeIcon)
  .use(Toaster)
  .use(Loading)
  .use(VueSocketIOExt, socket) // 初始化时不要自动连接
  .mixin({
    methods: {
      toThousands,
      secondsRemain
    }
  })
  .mount('#app')
