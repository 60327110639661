import { createStore, createLogger } from 'vuex'

import auth from '@/store/modules/auth'
import cart from '@/store/modules/cart'
import order from '@/store/modules/order'
import reer from '@/store/modules/reer'
import message from '@/store/modules/message'

const debug = process.env.NODE_ENV !== 'production'

export default createStore({
  modules: {
    auth,
    cart,
    order,
    reer,
    message
  },
  strict: debug,
  plugins: debug ? [createLogger()] : []
}
)
