import { CART_ITEMS, CART_COUNT } from '@/store/types'

const state = {
  items: JSON.parse(localStorage.getItem(CART_ITEMS)) || [],
  cartCount: localStorage.getItem(CART_COUNT) || 0
}

const mutations = {
  SET_ITEMS: (state, items) => {
    state.items = items
  },

  REMOVE_SELECTED_ITEMS: (state, country) => {
    state.items = state.items.filter(item => item.country !== country)
    localStorage.setItem(CART_ITEMS, JSON.stringify(state.items)) // 更新
  },

  SET_CART_COUNT: (state, count) => {
    state.cartCount = count
  }
}

const actions = {
  checkOutCart ({ commit }, { items }) {
    localStorage.setItem(CART_ITEMS, JSON.stringify(items))
    commit('SET_ITEMS', items)
  },

  clearCart ({ commit }) {
    localStorage.removeItem(CART_ITEMS)
    commit('SET_ITEMS', [])
  },
  removeSelectedItems ({ commit }, country) {
    commit('REMOVE_SELECTED_ITEMS', country)
  },

  updateCartCount ({ commit }, count) {
    localStorage.setItem(CART_COUNT, count)
    commit('SET_CART_COUNT', count)
  }
}

const getters = {
  countries: state => {
    const countries = []
    // let countryIds = {}
    state.items.forEach((item) => {
      if (countries.indexOf(item.country) < 0) {
        countries.push(item.country)
        // countryIds[item.country] = item.country_id
      }
    })
    return countries.sort()
  },

  countryIds: state => {
    const countries = []
    const countryIds = {}
    state.items.forEach((item) => {
      if (countries.indexOf(item.country) < 0) {
        countries.push(item.country)
        countryIds[item.country] = item.country_id
      }
    })
    return countryIds
  }
}

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations
}
