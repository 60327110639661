// 将整数转为每三位插入一个逗号
// import bcrypt from 'bcrypt'
import moment from 'moment'

export function toThousands (value) {
  let num = (value || 0).toString(); let result = ''
  while (num.length > 3) {
    result = ',' + num.slice(-3) + result
    num = num.slice(0, num.length - 3)
  }
  if (num) {
    result = num + result
  }
  return result
}

function isString (str) {
  // eslint-disable-next-line eqeqeq
  return (typeof str === 'string') && str.constructor == String
}

// 已经过去的秒数 = 当前时间 - 开始时间，总秒数 - 已经过去的秒数，等于剩下的秒数
export function secondsRemain (startDate, totalSeconds) {
  if (isString(startDate)) {
    startDate = new Date(startDate)
  }

  const currentDate = new Date()
  const seconds = Math.floor((currentDate.getTime() - startDate.getTime()) / 1000)

  return totalSeconds - seconds
}

export function fromNow (dateTime) {
  return moment(dateTime).fromNow()
}
